<template>
  <canvas v-if="mainPage" id="stage"></canvas>
</template>

<script>
export default {
  name: 'CanvasBlock',
  computed: {
    mainPage() {
      return this.$route.path === '/';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
