<template>
  <div v-if="contactPage" class="chat">
    <div class="chat__box">
      <span class="chat__box__bottom"></span>
      <div class="chat__box__line">
        <div class="chat__box__line__progress">
          <span class="chat__box__line__progress__scale"></span>
        </div>
      </div>
      <div class="chat__box__top">
        <div class="chat__box__top__return"><span>Powrót</span>
          <div class="chat__box__top__return__box chat__box__top__return__box--1">
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--1--1">P</div>
              <div class="chat__box__top__return__letter__sub">P</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--1--2">o</div>
              <div class="chat__box__top__return__letter__sub">o</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--1--3">w</div>
              <div class="chat__box__top__return__letter__sub">w</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--1--4">r</div>
              <div class="chat__box__top__return__letter__sub">r</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--1--5">ó</div>
              <div class="chat__box__top__return__letter__sub">ó</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--1--6">t</div>
              <div class="chat__box__top__return__letter__sub">t</div>
            </div>
          </div>
          <div class="chat__box__top__return__box chat__box__top__return__box--2">
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--2--1">P</div>
              <div class="chat__box__top__return__letter__sub">P</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--2--2">o</div>
              <div class="chat__box__top__return__letter__sub">o</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--2--3">w</div>
              <div class="chat__box__top__return__letter__sub">w</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--2--4">r</div>
              <div class="chat__box__top__return__letter__sub">r</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--2--5">ó</div>
              <div class="chat__box__top__return__letter__sub">ó</div>
            </div>
            <div class="chat__box__top__return__letter">
              <div class="chat__box__top__return__letter__main chat__box__top__return__letter__box--2--6">t</div>
              <div class="chat__box__top__return__letter__sub">t</div>
            </div>
          </div>
          <div class="chat__box__top__return__arrow">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Powrót</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="chat__box__messages">
        <div class="chat__box__messages__box">
          <div class="chat__box__messages__box__icon">
            <img src="~@/assets/sphere.png" alt="logo" class="chat__box__messages__box__icon__logo">
          </div>
          <div class="chat__box__messages__box__text">
            <div class="chat__box__messages__box__text__line">Cześć</div>
            <div class="chat__box__messages__box__text__line">Miło mi, że mogę rozpocząć rozmowę.</div>
          </div>
        </div>
        <div class="chat__box__messages__box chat__box__messages__box--second">
          <div class="chat__box__messages__box__icon">
            <img src="~@/assets/sphere.png" alt="logo" class="chat__box__messages__box__icon__logo">
          </div>
          <div class="chat__box__messages__box__text">
            <div class="chat__box__messages__box__text__line">Jak masz na imię?</div>
          </div>
        </div>
      </div>
      <div class="chat__box__type">
        <input class="chat__box__type__input" type="text" placeholder="Tutaj podaj odpowiedź...">
        <button class="chat__box__type__btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Wyślij</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"/>
          </svg>
        </button>
      </div>
      <div class="chat__box__groups chat__box__groups__types">
        <div class="chat__box__groups__element" data-id="0">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>Tworzenie stron internetowych</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="1">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>Budowanie aplikacji</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="2">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>Programowanie/Outsourcing</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="3">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>UX & UI</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="4">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>Branding</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
      </div>
      <div class="chat__box__groups chat__box__groups__prices">
        <div class="chat__box__groups__element" data-id="1">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>Poniżej 2000zł</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="1">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>2000zł - 5000zł</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="2">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>5000zł - 10000zł</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="3">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>10000zł - 25000zł</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
        <div class="chat__box__groups__element" data-id="4">
          <span class="chat__box__groups__element__checkbox"></span>
          <p>Powyżej 25000zł</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="chat__box__groups__element__check" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatBox',
  computed: {
    contactPage() {
      return this.$route.path === '/kontakt';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chat {
  margin-right: 5%;
  margin-left: 5%;
  position: fixed;
  z-index: 4;
  height: 100%;
  top: 0;
  width: 90%;
  visibility: hidden;
  @include respond(tab-land-plus) {
    width: calc(95% + 1px);
    margin-left: 2.5%;
  }

  &__box {
    position: relative;
    height: 100%;
    overflow: hidden;

    &__top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100px;
      background: black;
      z-index: 1;
      @include respond(tab-port) {
        height: 80px;
      }
      @include respond(phone) {
        height: 60px;
      }

      &__return {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        font-size: 2rem;
        font-weight: 700;
        color: $color-white;
        height: 28px;

        @include respond(tab-port) {
          margin-top: 4px;
          font-size: 1.7rem;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        & span {
          opacity: 0;
        }

        &__arrow {
          position: absolute;
          left: 140px;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
          height: 21px;
          opacity: 0;
          transition: left .7s ease, opacity .2s ease;

          & svg {
            width: 25px;
            height: 25px;
            & path {
              stroke-width: 50px;
              stroke: $color-white;
            }
          }
        }

        &__box {
          position: absolute;
          left: 0;
          top: 0;
          display: flex;

          &--2 {
            margin-left: 30px;
          }
        }
        @include respond(not-tab-port) {
          &:hover {
            & .chat__box__top__return__arrow {
              opacity: 1;
              left: 0;
              transition: left .7s ease, opacity .3s ease;
            }
            & .chat__box__top__return__box--1 .chat__box__top__return__letter .chat__box__top__return__letter__box--1--1 {
              transform: translateY(100%);
              transition: all .1s .25s ease-in;
            }
            & .chat__box__top__return__box--1 .chat__box__top__return__letter .chat__box__top__return__letter__box--1--2 {
              transform: translateY(100%);
              transition: all .1s .2s ease-in;
            }
            & .chat__box__top__return__box--1 .chat__box__top__return__letter .chat__box__top__return__letter__box--1--3 {
              transform: translateY(100%);
              transition: all .1s .15s ease-in;
            }
            & .chat__box__top__return__box--1 .chat__box__top__return__letter .chat__box__top__return__letter__box--1--4 {
              transform: translateY(100%);
              transition: all .1s .1s ease-in;
            }
            & .chat__box__top__return__box--1 .chat__box__top__return__letter .chat__box__top__return__letter__box--1--5 {
              transform: translateY(100%);
              transition: all .1s .05s ease-in;
            }
            & .chat__box__top__return__box--1 .chat__box__top__return__letter .chat__box__top__return__letter__box--1--6 {
              transform: translateY(100%);
              transition: all .1s ease-in;
            }

            & .chat__box__top__return__box--2 .chat__box__top__return__letter .chat__box__top__return__letter__box--2--1 {
              transform: translateY(0);
              transition: all .1s .45s ease-in;
            }
            & .chat__box__top__return__box--2 .chat__box__top__return__letter .chat__box__top__return__letter__box--2--2 {
              transform: translateY(0);
              transition: all .1s .4s ease-in;
            }
            & .chat__box__top__return__box--2 .chat__box__top__return__letter .chat__box__top__return__letter__box--2--3 {
              transform: translateY(0);
              transition: all .1s .35s ease-in;
            }
            & .chat__box__top__return__box--2 .chat__box__top__return__letter .chat__box__top__return__letter__box--2--4 {
              transform: translateY(0);
              transition: all .1s .3s ease-in;
            }
            & .chat__box__top__return__box--2 .chat__box__top__return__letter .chat__box__top__return__letter__box--2--5 {
              transform: translateY(0);
              transition: all .1s .25s ease-in;
            }
            & .chat__box__top__return__box--2 .chat__box__top__return__letter .chat__box__top__return__letter__box--2--6 {
              transform: translateY(0);
              transition: all .1s .2s ease-in;
            }
          }
        }

        &__letter {
          overflow: hidden;
          position: relative;

          &__box--1--1 {
            transition: all .1s .45s ease-in;
          }
          &__box--1--2 {
            transition: all .1s .4s ease-in;
          }
          &__box--1--3 {
            transition: all .1s .35s ease-in;
          }
          &__box--1--4 {
            transition: all .1s .3s ease-in;
          }
          &__box--1--5 {
            transition: all .1s .25s ease-in;
          }
          &__box--1--6 {
            transition: all .1s .2s ease-in;
          }


          &__box--2--1 {
            transition: all .1s .25s ease-in;
            transform: translateY(-100%);
          }
          &__box--2--2 {
            transition: all .1s .2s ease-in;
            transform: translateY(-100%);
          }
          &__box--2--3 {
            transition: all .1s .15s ease-in;
            transform: translateY(-100%);
          }
          &__box--2--4 {
            transition: all .1s .1s ease-in;
            transform: translateY(-100%);
          }
          &__box--2--5 {
            transition: all .1s .05s ease-in;
            transform: translateY(-100%);
          }
          &__box--2--6 {
            transition: all .1s ease-in;
            transform: translateY(-100%);
          }

          &__main {
            position: absolute;
            left: 0;
            top: 0;
          }

          &__sub {
            opacity: 0;
          }
        }
      }
    }

    &__groups {
      position: absolute;
      bottom: 60px;
      width: 100%;
      left: -100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: left;

      &__element {
        font-size: 1.4rem;
        background: $color-white;
        color: $color-dark-gray;
        border-radius: 25px;
        padding: 10px 15px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transition: color .5s ease-out, background .5s ease-out;
        position: relative;
        cursor: pointer;

        @include respond(tab-port) {
          font-size: 1.2rem;
          padding: 7px 12px;
        }
        @include respond(phone) {
          font-size: .9rem;
          padding: 5px 8px;
        }

        &:hover {
          background: $color-gray;
          color: $color-white;
          & .chat__box__groups__element__check {
            transform: translateY(-50%) scale(1);
          }
          & .chat__box__groups__element__checkbox {
            background: $color-gray;
          }
        }

        &__check {
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%) scale(0);
          background: transparent;
          width: 21px;
          height: 21px;
          transition: all .5s ease;
          @include respond(tab-port) {
            left: 11px;
            width: 16px;
            height: 16px;
          }
          @include respond(phone) {
            left: 7px;
            width: 12px;
            height: 12px;
          }
          & path {
            stroke: $color-white;
            stroke-width: 40px;
          }
        }

        &__checkbox {
          height: 15px;
          width: 15px;
          border-radius: 5px;
          border: 1px solid $color-gray;
          background: $color-white;
          display: block;
          margin-bottom: 1px;
          margin-right: 5px;
          transition: background .5s ease;
          @include respond(tab-port) {
            border-radius: 2px;
            width: 13px;
            height: 13px;
          }
          @include respond(phone) {
            height: 10px;
            width: 10px;
            border-radius: 2px;
          }
        }

        &--checked {
          background: $color-gray;
          color: $color-white;
          & .chat__box__groups__element__check {
            transform: translateY(-50%) scale(1);
          }
          & .chat__box__groups__element__checkbox {
            background: $color-gray;
          }
        }
      }
    }

    &__line {
      position: absolute;
      flex: 0 0 auto;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
      height: 0;
      z-index: 3;

      @include respond(tab-port) {
        top: 80px;
      }
      @include respond(phone) {
        top: 60px;
      }

      &__progress {
        position: relative;
        height: 2px;
        width: 100%;
        background-color: $color-white;

        &__scale {
          transform: scaleX(0);
          transform-origin: left top;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $color-gray;
        }
      }
    }

    &__left {
      position: absolute;
      height: 100%;
      left: 0;
      width: 2px;
      background: $color-gray;
    }

    &__right {
      position: absolute;
      right: 0;
      height: 100%;
      width: 2px;
      background: $color-gray;
    }

    &__bottom {
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
      height: 2px;
      width: 0;
      background: $color-gray;

      @include respond(tab-port) {
        bottom: 50px;
      }
      @include respond(phone) {
        bottom: 40px;
      }
    }

    &__messages {
      width: 95%;
      position: absolute;
      height: 130%;
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-flow: column nowrap;
      align-items: baseline;
      justify-content: flex-end;

      &__box {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        max-width: 800px;
        margin-top: 30px;

        @include respond(tab-port) {
          margin-top: 15px;
        }
        @include respond(phone) {
          margin-top: 10px;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 400px;
          @include respond(tab-port) {
            margin-bottom: 370px;
          }
          @include respond(phone) {
            margin-bottom: 360px;
          }
        }

        &__response {
          padding: 18px 23px 15px;
          font-size: 1.8rem;
          @include respond(tab-port) {
            padding: 11px 16px 10px;
            font-size: 1.3rem;
          }
          @include respond(phone) {
            padding: 9px 12px 7px;
            font-size: 1rem;
          }
        }

        &--user {
          border-radius: 30px 0 30px 30px;
          background: $color-dark-gray;
          color: $color-white;
          margin-left: auto;
          margin-right: 0;
        }

        &__icon {
          width: 70px;
          height: 70px;
          margin-right: 10px;
          background-image: url(@/assets/msg.png);
          background-size: 70px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include respond(tab-port) {
            width: 50px;
            height: 50px;
            background-size: 50px;
          }
          @include respond(phone) {
            width: 40px;
            height: 40px;
            background-size: 40px;
          }

          &__logo {
            height: 30px;
            @include respond(tab-port) {
              height: 18px;
            }
            @include respond(phone) {
              height: 14px;
            }
          }
        }
        &__text {
          color: $color-white;
          font-size: 1.8rem;
          max-width: calc(100% - 80px);
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          padding-top: 10px;
          @include respond(tab-port) {
            font-size: 1.3rem;
            padding-top: 7px;
          }
          @include respond(phone) {
            font-size: 1rem;
            padding-top: 5px;
          }
          &__line {
            padding-top: 3px;
            padding-bottom: 3px;
          }
        }
      }
    }

    &__type {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 2px;
      width: calc(100% - 4px);
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: black;
      @include respond(tab-port) {
        height: 50px;
      }
      @include respond(phone) {
        height: 40px;
      }

      & input {
        padding-left: 15px;
        font-size: 1.8rem;
        width: calc(100% - 65px);
        color: white;
        background: transparent;
        border: none;
        outline: none;
        font-family: 'Monument', 'sans-serif';
        @include respond(tab-port) {
          padding-left: 8px;
          font-size: 1.3rem;
        }
        @include respond(phone) {
          padding-left: 5px;
          font-size: 1rem;
        }
      }

      &__btn {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-right: 10px;

        &:hover svg path {
          stroke: $color-orange;
        }

        & svg {
          height: 40px;
          width: 40px;
          @include respond(tab-port) {
            height: 30px;
            width: 30px;
          }
          @include respond(phone) {
            height: 25px;
            width: 25px;
          }
          & path {
            stroke: $color-white;
            stroke-width: 30;
            transition: stroke .3s ease-in-out;
          }
        }
      }
    }
  }
}

.scrollbar--chat {
  opacity: 0 !important;
  transition: opacity 1s ease;
}
.chat-trans {
  background: transparent !important;
}


</style>
