<template>
  <div class="top">
    <div class="top__title">
      <div class="top__title--1 top__title__box">
        <h1 class="top__title__box__main">Tworzę</h1>
        <h1 class="top__title__box__sub">Tworzę</h1>
      </div>
      <div class="top__title--1 top__title__box">
        <h1 class="top__title__box__main">z Pasją</h1>
        <h1 class="top__title__box__sub">z Pasją</h1>
      </div>
      <div class="top__title--2 top__title__box">
        <h1 class="top__title__box__main">innowacyjne</h1>
        <h1 class="top__title__box__sub">innowacyjne</h1>
      </div>
      <div class="top__title--3 top__title__box">
        <h1 class="top__title__box__main">PRODUKTY</h1>
        <h1 class="top__title__box__sub">PRODUKTY</h1>
      </div>
      <div class="top__title--4 top__title__box">
        <h1 class="top__title__box__main">CYFROWE</h1>
        <h1 class="top__title__box__sub">CYFROWE</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBlock',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top {
  background: transparent;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include respond(tab-land-plus) {
    width: 95%;
  }

  &__height {
    width: 100%;
    min-height: calc(100vh - 42rem);
    @include respond(tab-land-plus) {
      min-height: calc(100vh - 22rem - 20vw);
    }
    @include respond(tab-port) {
      min-height: calc(100vh - 2rem - 48vw);
    }
  }

  &__projects {
    position: relative;
    height: 60rem;
    background: transparent;
    &__box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      & div {
        color: $color-white;
        line-height: .9;
        font-size: 7vw;
        font-family: 'Monument', 'sans-serif';
        font-weight: 700;
      }
      & span {
        color: $color-orange;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    padding-top: 80px;

    @include respond(tab-port) {
      padding-top: 85px;
    }

    @include respond(phone) {
      padding-top: 75px;
    }

    &--lower {
      padding-top: 120px;
    }

    &__btn-box {
      margin-top: 5rem;
      opacity: 0;

      &__btn {
        cursor: pointer;
        display: inline-block;
        text-transform: uppercase;
        font-size: 2rem;
        border: 2px solid $color-orange;
        background: $color-orange;
        color: white;
        padding: 25px 50px 20px;
        font-family: 'Monument', 'sans-serif';
        font-weight: 700;
        transition: background 1s ease-out;

        @include respond(tab-port) {
          font-size: 2vw;
        }

        @include respond(phone) {
          font-size: 3vw;
          padding: 16px 25px 13px;
        }

        &:hover {
          background: black;
        }
      }
    }

    &--2 {
      margin-left: 15%;
    }

    &--4 {
      margin-left: 30%;
    }

    &--special-height {
      & h1 {
        line-height: 1.2 !important;
      }
    }

    &--3 h1 {
      color: $color-orange !important;
    }

    &--orange h1 {
      color: $color-orange !important;
    }

    &__box {
      overflow: hidden;
      position: relative;
      margin-top: -1.1vw;

      &--padding {
        padding-bottom: 30px;
      }

      & h1 {
        color: $color-white;
        line-height: 1.1;
        font-size: 7vw;
        font-family: 'Monument', 'sans-serif';
        font-weight: 700;

        & span {
          color: $color-orange;
        }
      }

      &--higher {
        & h1 {
          line-height: 1.5;
        }
      }

      &__sub {
        opacity: 0;
        pointer-events: none;
      }

      &__main {
        position: absolute;
        transform: translateY(110%);
      }

      &--last {
        display: inline-block;
        color: $color-orange !important;
        cursor: pointer;

        &:hover::after {
          animation-name: underLeftToRight;
          animation-duration: 0.8s;
        }
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          bottom: -1.2vw;
          left: 0;
          height: .8vw;
          background: $color-orange;
          //transition: width 1s ease;
          transition: cubic-bezier(0.19, 1, 0.22, 1) 0.8s width;
          animation-name: underRightToLeft;
          animation-duration: 0.3s;
          animation-fill-mode: both;
          animation-direction: alternate;

        }
      }
    }
  }
}
</style>
