<template>
  <div class="header">
    <ul class="header__list">
      <li class="header__list__element">
        <router-link to="/" class="header__block__link">
          <img src="~@/assets/sphere.png" alt="logo">
        </router-link>
      </li>
      <li class="header__list__element header__list__element--desktop">
        <router-link to="/portfolio" class="header__block__link">
          <span v-if="portfolioPage" class="header__active">Portfolio</span>
          <span v-else>Portfolio</span>
        </router-link>
      </li>
      <li class="header__list__element header__list__element--desktop">
        <router-link to="/o-mnie" class="header__block__link">
          <span v-if="aboutPage" class="header__active">O mnie</span>
          <span v-else>O mnie</span>
        </router-link>
      </li>
      <li class="header__list__element header__list__element--desktop">
        <router-link to="/kontakt" class="header__block__link">
          <span v-if="contactPage" class="header__active">Kontakt</span>
          <span v-else>Kontakt</span>
        </router-link>
      </li>
      <li class="header__list__element header__list__element--mobile">
        <button class="header__list__element__btn">
          <span class="header__list__element__btn__line header__list__element__btn__line--1"></span>
          <span class="header__list__element__btn__line header__list__element__btn__line--2"></span>
          <span class="header__list__element__btn__line header__list__element__btn__line--3"></span>
          <span class="header__list__element__btn__line--4"></span>
          <span class="header__list__element__btn__line--5"></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HeaderTab',
  computed: {
    portfolioPage() {
      return this.$route.path === '/portfolio';
    },
    aboutPage() {
      return this.$route.path === '/o-mnie';
    },
    contactPage() {
      return this.$route.path === '/kontakt';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 5%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  pointer-events: none;
  transition: top 0.3s .1s;
  transform: translateY(-100px);

  @include respond(tab-land-plus) {
    width: calc(95% + 1px);
    left: 2.5%;
  }
  @include respond(tab-port) {
    height: 80px;
    background: black;
    width: 100%;
    left: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  @include respond(phone) {
    height: 60px;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    pointer-events: none;

    @include respond(tab-port) {
      justify-content: space-between;
      width: 100%;
    }

    &__element {
      list-style: none;
      pointer-events: all;
      padding-right: 30px;
      font-size: 2rem;
      font-weight: 700;
      color: white;
      display: flex;
      align-items: center;
      @include respond(tab-port) {
        padding-right: 0;
      }

      &--desktop {
        @include respond(tab-port) {
          display: none;
        }
      }

      &--mobile {
        display: none;
        @include respond(tab-port) {
          display: flex;
        }
      }

      &__btn {
        border: none;
        background: transparent;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 26px;
        width: 40px;
        cursor: pointer;

        @include respond(phone) {
          width: 30px;
          height: 18px;
        }

        &__line {
          height: 2px;
          background: $color-white;
          width: 100%;
          transform-origin: right;

          &--3 {
            width: 70%;
            margin-right: 0;
            margin-left: auto;
          }

          &--4 {
            background: $color-orange;
            position: absolute;
            height: 2px;
            width: 100%;
            transform-origin: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg) scaleX(0);
          }
          &--5 {
            background: $color-orange;
            position: absolute;
            height: 2px;
            width: 100%;
            transform-origin: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg) scaleX(0);
          }
        }
      }

      & a {
        color: white;
        text-decoration: none;
        & img {
          height: 50px;
          @include respond(phone) {
            height: 43px;
          }
        }

        & span {
          position: relative;
          &:hover::after {
            animation-name: underLeftToRight;
            animation-duration: 0.8s;
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -.2vw;
            width: 100%;
            height: .1vw;
            background: white;
            transition: cubic-bezier(0.19, 1, 0.22, 1) 0.8s width;
            animation-name: underRightToLeft;
            animation-duration: 0.3s;
            animation-fill-mode: both;
            animation-direction: alternate;
          }
        }
      }
    }
  }
}

</style>
