<template>
  <div class="projects">
    <div class="projects__box">
      <div class="projects__box__line">
        <div class="projects__box__line--letters">Wybrane<span>_</span></div>
      </div>
      <div class="projects__box__line projects__box__line--2">
        <div class="projects__box__line--letters">Projekty</div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsBlock'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.projects {
  position: relative;
  height: 40vw;
  background: transparent;

  &__box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    &__line {
      overflow: hidden;
      position: relative;
      &--2 {
        margin-top: -1.1vw;
      }

      &--letters {
        color: #e8e8e8;
        font-size: 7vw;
        line-height: 1.1;
        font-family: 'Monument', 'sans-serif';
        font-weight: 700;
        transform: translateY(110%);

        & span {
          color: #a33921;
        }
      }
    }
  }
}
</style>
