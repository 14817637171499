<template>
  <div class="about">
    <div class="about__image-box about__svg-box--image">
      <img class="about__svg-box__figure" src="@/assets/ciekawosc.png" :data-hover="faceImg" alt="find me">
    </div>
    <div class="about__svg-box">
      <span class="about__svg-box__left"></span>
      <span class="about__svg-box__bottom"></span>
    </div>
    <div class="about__content">
      <div class="about__content__text">
        <div class="about__content__text__hey">
          <div class="about__content__text__hey__line">
            <span class="about__content__text__hey__line--sub">Hej, jestem</span>
            <span class="about__content__text__hey__line--real">Hej, jestem</span>
          </div>
          <div class="about__content__text__hey__line">
            <span class="about__content__text__hey__line--sub">Arkadiusz Krupka</span>
            <span class="about__content__text__hey__line--real">Arkadiusz Krupka</span>
          </div>
        </div>
        <div class="about__content__text__box about__content__text__box--1">
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              Jako fullstack developer głównie zajmuję się
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              tworzeniem stron oraz aplikacji internetowych.
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              Klienci krajowi i międzynarodowi polegali na mnie
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              w zakresie projektowania, wdrażania
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              i zarządzania swoimi produktami cyfrowymi.
            </div>
          </div>
        </div>
        <div class="about__content__text__box about__content__text__box--2">
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              Poza budową stron zajmuję się również
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              outsourcingiem, i jako freelancer,
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              współpracuje z agencjami internetowymi,
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              firmami, startupami i osobami prywatnymi.
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              Wykonuje REST API, layouty, wtyczki i wiele
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              więcej wykorzystując głównie PHP(Laravel,
            </div>
          </div>
          <div class="about__content__text__box__line">
            <div class="about__content__text__box__line--letters">
              Slim, Symfony), JS(ES6) i SCSS.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Scene from '@/assets/Scene.js'
import faceImg from "@/assets/face.png"
import curiousImg from "@/assets/ciekawosc.png"

export default {
  name: 'AboutBlock',
  computed: {
    faceImg() {
      return faceImg
    }
  },
  data() {
    return {
      sceneObject: {}
    }
  },
  mounted() {
    this.sceneObject = null
    let sceneActivated = false
    if (document.querySelector('.about')) {
      if (window.innerWidth > 900) {
        sceneActivated = true
        this.sceneObject = new Scene()
      } else {
        let figurineImg = document.querySelector('.about__svg-box__figure')
        if (figurineImg) {
          figurineImg.src = faceImg
        }
      }
    }

    let currentWidth = window.innerWidth
    window.addEventListener('resize', () => {
      if (currentWidth > 900 && window.innerWidth <= 900) {
        let figurineImg = document.querySelector('.about__svg-box__figure')
        if (figurineImg) {
          figurineImg.src = faceImg
          figurineImg.style.opacity = 1
        }
      }
      if (currentWidth <= 900 && window.innerWidth > 900) {
        let figurineImg = document.querySelector('.about__svg-box__figure')
        if (figurineImg) {
          figurineImg.src = curiousImg
          figurineImg.style.opacity = 0
        }
        if (sceneActivated === false && document.querySelector('.about')) {
          this.sceneObject = new Scene()
          sceneActivated = true
        }
      }
      currentWidth = window.innerWidth
    })
  },
  beforeUnmount() {
    if (this.sceneObject) {
      this.sceneObject.killListeners()
      delete this.sceneObject
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.about {
  position: relative;
  width: 100%;
  display: flex;
  @include respond(phone) {
    flex-direction: column;
  }

  &__svg-box {
    position: absolute;
    width: 40%;
    height: 100%;
    left: 5%;
    z-index: 2;
    cursor: none;
    @include respond(tab-port) {
      cursor: auto;
    }
    @include respond(phone) {
      display: none;
    }

    &__left {
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      width: 2px;
      background: $color-gray;
    }
    &__bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 0;
      background: $color-gray;
    }
    &--image {
      z-index: 2;
    }
    & svg {
      width: 100%;
      height: 100%;
      stroke-width: .2vw;
      fill: none;
      stroke: $color-gray;
    }

  }

  &__image-box {
    width: 40%;
    margin-left: 5%;
    cursor: none;
    @include respond(tab-port) {
      cursor: auto;
    }
    @include respond(phone) {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5vw;
    }

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__content {
    width: 40%;
    position: relative;
    @include respond(tab-land) {
      width: 50%;
    }
    @include respond(phone) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2vw;
      z-index: 2;
    }

    &__text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include respond(phone) {
        position: relative;
        top: 0;
        transform: none;
      }

      &__hey {
        position: absolute;
        left: 0;
        top: -4vw;
        font-size: 1.6vw;
        color: $color-orange;
        font-family: 'HeartVibes', 'sans-serif';
        display: flex;
        flex-wrap: wrap;
        font-weight: 700;

        @include respond(phone) {
          top: -8vw;
          font-size: 3vw;
        }

        &__line {
          width: 100%;
          overflow: hidden;
          position: relative;

          &--sub {
            opacity: 0;
          }

          &--real {
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(100%);
          }
        }
      }

      &__box {
        margin-bottom: 2rem;
        @include respond(phone) {
          margin-bottom: 0;
        }
        &--2 {
          @include respond(phone) {
            margin-top: 1rem;
          }
        }

        &__line {
          overflow: hidden;
          position: relative;
          &--letters {
            transform: translateY(100%);
            font-size: .9vw;
            color: $color-white;
            @include respond(tab-land-plus) {
              font-size: 1.1vw;
            }
            @include respond(tab-land) {
              font-size: 1.2vw;
            }
            @include respond(tab-port) {
              font-size: 1.3vw;
            }
            @include respond(phone) {
              font-size: 2.3vw;
            }
          }
        }
      }
    }
  }
}
</style>
