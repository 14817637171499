<template>
  <IntroPage />
  <PixelBg />
  <NavigationTab />
  <HeaderTab />
  <main>
    <router-view/>
    <FooterBlock />
  </main>
  <ChatBox />
  <ScrollBar />
  <CanvasBlock />
</template>

<script>
import IntroPage from '@/components/IntroPage.vue'
import PixelBg from '@/components/PixelBg.vue'
import NavigationTab from '@/components/NavigationTab.vue'
import HeaderTab from '@/components/HeaderTab.vue'
import ChatBox from '@/components/ChatBox.vue'
import ScrollBar from '@/components/ScrollBar.vue'
import CanvasBlock from '@/components/CanvasBlock.vue'
import FooterBlock from '@/components/FooterBlock.vue'

export default {
  name: 'App',
  components: {
    IntroPage,
    PixelBg,
    NavigationTab,
    HeaderTab,
    ChatBox,
    ScrollBar,
    CanvasBlock,
    FooterBlock
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  background: black;
  overscroll-behavior: contain;
}

body {
  box-sizing: border-box;
  font-family: 'Monument', 'sans-serif';
  overflow-x: hidden;
  overscroll-behavior: contain;
  @include respond(tab-land) {
    padding: 0;
  }
  background: transparent;
  height: 100%;
}

main {
  position: relative;
  z-index: 1;
  width: 100vw;
}
canvas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
</style>
