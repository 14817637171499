<template>
  <nav class="navigation">
    <div class="navigation__background"></div>
    <ul class="navigation__list">
      <li class="navigation__list__element">
        <router-link to="/portfolio" class="navigation__list__element__link">
          <span v-if="portfolioPage" class="navigation__list__element__link__main navigation__active">Portfolio</span>
          <span v-else class="navigation__list__element__link__main">Portfolio</span>
          <span class="navigation__list__element__link__sub">Portfolio</span>
        </router-link>
      </li>
      <li class="navigation__list__element">
        <router-link to="/o-mnie" class="navigation__list__element__link">
          <span v-if="aboutPage" class="navigation__list__element__link__main navigation__active">O mnie</span>
          <span v-else class="navigation__list__element__link__main">O mnie</span>
          <span class="navigation__list__element__link__sub">O mnie</span>
        </router-link>
      </li>
      <li class="navigation__list__element">
        <router-link to="/kontakt" class="navigation__list__element__link">
          <span v-if="contactPage" class="navigation__list__element__link__main navigation__active">Kontakt</span>
          <span v-else class="navigation__list__element__link__main">Kontakt</span>
          <span class="navigation__list__element__link__sub">Kontakt</span>
        </router-link>
      </li>
    </ul>
    <div class="navigation__credit">
      ©2022 SDAK
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationTab',
  computed: {
    portfolioPage() {
      return this.$route.path === '/portfolio';
    },
    aboutPage() {
      return this.$route.path === '/o-mnie';
    },
    contactPage() {
      return this.$route.path === '/kontakt';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  visibility: hidden;

  &__credit {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: $color-white;
    font-size: 1rem;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__element {
      position: relative;
      text-align: center;
      overflow: hidden;
      margin-top: 10px;
      margin-bottom: 10px;

      &__link {
        position: relative;
        color: $color-white;
        font-size: 6vw;
        font-family: 'Monument', 'sans-serif';
        font-weight: 700;
        text-decoration: none;

        &__sub {
          opacity: 0;
          pointer-events: none;

          & img {
            height: 13vw;
          }
        }

        &__main {
          position: absolute;
          transform: translateY(150%);

          & img {
            height: 13vw;
          }
        }
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    transform: scaleY(0);
    transform-origin: top;
  }
}
</style>
