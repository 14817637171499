<template>
  <div class="images">
    <div class="images__section">
      <div class="images__section__list-box">
        <ul class="images__section__list-box__list">
          <li class="images__section__list-box__list__element"><span>branding</span></li>
          <li class="images__section__list-box__list__element"><span>ux/ui</span></li>
          <li class="images__section__list-box__list__element"><span>backend</span></li>
          <li class="images__section__list-box__list__element"><span>frontend</span></li>
        </ul>
      </div>
      <div class="images__section__bg">
        <div class="images__section__bg__img images__section__bg__img--3"></div>
      </div>
      <div class="images__section__link">
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/stolarnia-wolin">stolarnia wolin</router-link>
        </h3>
      </div>
    </div>
    <div class="images__section">
      <div class="images__section__list-box">
        <ul class="images__section__list-box__list">
          <li class="images__section__list-box__list__element"><span>branding</span></li>
          <li class="images__section__list-box__list__element"><span>ux/ui</span></li>
          <li class="images__section__list-box__list__element"><span>frontend</span></li>
        </ul>
      </div>
      <div class="images__section__bg">
        <div class="images__section__bg__img images__section__bg__img--1"></div>
      </div>
      <div class="images__section__link">
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/wagi-paczkowo">wagi paczkowo</router-link>
        </h3>
      </div>
    </div>
    <div class="images__section">
      <div class="images__section__list-box">
        <ul class="images__section__list-box__list">
          <li class="images__section__list-box__list__element"><span>graphics</span></li>
          <li class="images__section__list-box__list__element"><span>frontend</span></li>
        </ul>
      </div>
      <div class="images__section__bg">
        <div class="images__section__bg__img images__section__bg__img--2"></div>
      </div>
      <div class="images__section__link">
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/butik-mjw">butik mjw</router-link>
        </h3>
      </div>
    </div>
    <div class="images__section">
      <div class="images__section__list-box">
        <ul class="images__section__list-box__list">
          <li class="images__section__list-box__list__element"><span>ux/ui</span></li>
          <li class="images__section__list-box__list__element"><span>backend</span></li>
          <li class="images__section__list-box__list__element"><span>frontend</span></li>
        </ul>
      </div>
      <div class="images__section__bg">
        <div class="images__section__bg__img images__section__bg__img--4"></div>
      </div>
      <div class="images__section__link">
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
        <h3>
          <router-link to="/portfolio/mi-reo">mi reo</router-link>
        </h3>
      </div>
    </div>
  </div>
  <div class="images">
    <div class="images__bottom">
      <div class="images__bottom__btn">
        <span class="images__bottom__btn--left"></span>
        <span class="images__bottom__btn--right"></span>
        <span class="images__bottom__btn--bottom"></span>
        <span class="images__bottom__btn--top"></span>
        <router-link to="/portfolio" class="images__bottom__btn__link">Wszystkie</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagesBlock'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes scrolling-text-animation {
  0% {
    transform: translateX(-20%) translateZ(0);
  }
  100% {
    transform: translateX(-120%) translateZ(0);
  }
}
.images {
  position: relative;
  background: transparent;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include respond(tab-land-plus) {
    width: 95%;
  }

  &__bottom {
    height: 50rem;
    position: relative;

    @include respond(tab-land) {
      height: 40vw;
    }

    &__btn {
      position: absolute;
      left: 0;
      top: 40%;
      transform: translateY(-50%);
      padding: 2.7vw 4vw 2vw;
      cursor: pointer;

      &:hover {
        & .images__bottom__btn--left {
          height: 100% !important;
          transition: all 1s ease;
        }
        & .images__bottom__btn--right {
          height: 100% !important;
          transition: all 1s ease;
        }
        & .images__bottom__btn--top {
          width: 100% !important;
          transition: all 1s ease;
        }
        & .images__bottom__btn--bottom {
          width: 100% !important;
          transition: all 1s ease;
        }
      }

      &__link {
        font-size: 7vw;
        color: $color-white;
        font-weight: 700;
        text-transform: uppercase;
        //border: 1px solid gray;
        text-decoration: none;
      }
      &--left {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 2px;
        height: 70%;
        background-color: $color-gray;
        transition: all 1s ease;
      }
      &--right {
        position: absolute;
        right: 0;
        top: 0;
        width: 2px;
        height: 70%;
        background-color: $color-gray;
        transition: all 1s ease;
      }
      &--top {
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        width: 70%;
        background-color: $color-gray;
        transition: all 1s ease;
      }
      &--bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 70%;
        background-color: $color-gray;
        transition: all 1s ease;
      }
    }
  }

  &__section {
    height: 500px;
    margin-bottom: 20px;
    background: transparent;
    overflow: hidden;
    position: relative;
    @include respond(phone) {
      height: 60vw;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__list-box {
      position: absolute;
      left: 15px;
      top: 0;
      z-index: 2;

      &__list {
        display: flex;
        justify-content: flex-start;
        &__element {
          list-style: none;
          margin-top: 15px;
          margin-right: 4px;
          padding: 8px 20px 5px 20px;
          font-size: 2.4rem;
          font-weight: 700;
          text-transform: uppercase;
          color: white;
          border: 2px solid white;
          font-family: 'Bebas Neue', 'Monument', 'sans-serif';
          border-radius: 25px;

          @include respond(phone) {
            padding: .6vw 2vw .4vw 2vw;
            font-size: 4vw;
            font-weight: 400;
          }
        }
      }
    }

    &__link  {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      overflow: visible;
      white-space: nowrap;

      & h3 {
        font-size: 7vw;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: .50em;

        //transform: translateX(-20%) translateZ(0);
        animation: scrolling-text-animation linear infinite 20s;
        & a {
          text-decoration: none;
          color: white;
        }
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        width: 100%;
        height: 100%;

        &--1 {
          background-image: url(@/assets/test3.jpg);
          @include respond(phone) {
            background-image: url(@/assets/test3-phone.jpg);
          }
        }

        &--2 {
          background-image: url(@/assets/test2.jpg);
          @include respond(phone) {
            background-image: url(@/assets/test2-phone.jpg);
          }
        }

        &--3 {
          background-image: url(@/assets/test1.jpg);
          @include respond(phone) {
            background-image: url(@/assets/test1-phone.jpg);
          }
        }

        &--4 {
          background-image: url(@/assets/test4.jpg);
          @include respond(phone) {
            background-image: url(@/assets/test4-phone.jpg);
          }
        }
      }
    }
  }
}

</style>
