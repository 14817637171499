<template>
  <div class="pixel-bg"></div>
</template>

<script>
export default {
  name: 'PixelBg'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes pixelate {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}
.pixel-bg {
  position: fixed;
  pointer-events: none;
  animation: pixelate 1s steps(4) infinite;
  background-image: url(../assets/pix.png);
  height: 200%;
  left: -50%;
  top: -50%;
  width: 200%;
  opacity: .7;
  z-index: 99;
}

</style>
