import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Scrollbar from '@/assets/Scrollbar'

export default class Timeline {
    constructor() {
        gsap.registerPlugin(ScrollTrigger)
        const lineOne = document.querySelector('.loader__content__line--1')
        const timeline = gsap.timeline()
        let timeForTop = 4.1
        document.querySelector('#app').style.position = 'relative'
        const topTitleBtn = document.querySelector('.top__title__btn-box')
        if (topTitleBtn) {
            topTitleBtn.style.opacity = 0
        }
        document.querySelector('main').style.opacity = '1'
        document.querySelector('main').style.display = 'block'
        document.querySelectorAll('.projects__box__line--letters').forEach(ele => {
            ele.style.transform = 'translateY(110%)'
        })
        document.querySelectorAll('.top__title__box').forEach(ele => {
            ele.style.overflow = 'hidden'
        })
        document.querySelectorAll('.about__content__text__box__line--letters').forEach(ele => {
            ele.style.opacity = 0
        })
        document.querySelectorAll('.information__row__column__text').forEach(ele => {
            ele.style.opacity = 0
        })
        document.querySelectorAll('.about__content__text__hey__line--real').forEach(ele => {
            ele.style.opacity = 0
        })
        if (document.querySelector('.images')) {
            document.querySelector('main').style.transform = 'none'
        }
        const svgLeft = document.querySelector('.about__svg-box__left')
        if (svgLeft) {
            svgLeft.style.height = '0%'
        }
        const svgBottom = document.querySelector('.about__svg-box__bottom')
        if (svgBottom) {
            svgBottom.style.width = '0%'
        }
        if (lineOne) {
            const lineTwo = document.querySelector('.loader__content__line--2')
            const lineThree = document.querySelector('.loader__content__line--3')
            const lineFour = document.querySelector('.loader__content__line--4')
            const lineFive = document.querySelector('.loader__content__line--5')
            const lineSix = document.querySelector('.loader__content__line--6')
            const lineSeven = document.querySelector('.loader__content__line--7')
            const lineEight = document.querySelector('.loader__content__line--8')
            const lineNine = document.querySelector('.loader__content__line--9')
            const lineTen = document.querySelector('.loader__content__line--10')
            const lineEleven = document.querySelector('.loader__content__line--11')
            const loader = document.querySelector('.loader')
            const loaderContent = document.querySelector('.loader__content')
//mid to top show
            timeline.to(lineFive.querySelector('span'), {opacity: 1, duration: .01}, .3)
            timeline.to(lineFour.querySelector('span'), {opacity: 1, duration: .01}, .5)
            timeline.to(lineThree.querySelector('span'), {opacity: 1, duration: .01}, .7)
            timeline.to(lineTwo.querySelector('span'), {opacity: 1, duration: .01}, .8)
            timeline.to(lineOne.querySelector('span'), {opacity: 1, duration: .01}, .9)
//mid to bottom show
            timeline.to(lineSeven.querySelector('span'), {opacity: 1, duration: .01}, .3)
            timeline.to(lineEight.querySelector('span'), {opacity: 1, duration: .01}, .5)
            timeline.to(lineNine.querySelector('span'), {opacity: 1, duration: .01}, .7)
            timeline.to(lineTen.querySelector('span'), {opacity: 1, duration: .01}, .8)
            timeline.to(lineEleven.querySelector('span'), {opacity: 1, duration: .01}, .9)

//mid to top hide
            timeline.to(lineSix.querySelector('span'), {opacity: 0, duration: .01}, 1)
            timeline.to(lineFive.querySelector('span'), {opacity: 0, duration: .01}, 1.1)
            timeline.to(lineFour.querySelector('span'), {opacity: 0, duration: .01}, 1.2)
            timeline.to(lineThree.querySelector('span'), {opacity: 0, duration: .01}, 1.3)
            timeline.to(lineTwo.querySelector('span'), {opacity: 0, duration: .01}, 1.4)
            timeline.to(lineOne.querySelector('span'), {opacity: 0, duration: .01}, 1.5)
//mid to top hide
            timeline.to(lineSeven.querySelector('span'), {opacity: 0, duration: .01}, 1.1)
            timeline.to(lineEight.querySelector('span'), {opacity: 0, duration: .01}, 1.2)
            timeline.to(lineNine.querySelector('span'), {opacity: 0, duration: .01}, 1.3)
            timeline.to(lineTen.querySelector('span'), {opacity: 0, duration: .01}, 1.4)
            timeline.to(lineEleven.querySelector('span'), {opacity: 0, duration: .01}, 1.5)
//top to mid show
            timeline.to(lineOne.querySelector('span'), {opacity: 1, duration: .01}, 1.6)
            timeline.to(lineTwo.querySelector('span'), {opacity: 1, duration: .01}, 1.7)
            timeline.to(lineThree.querySelector('span'), {opacity: 1, duration: .01}, 1.8)
            timeline.to(lineFour.querySelector('span'), {opacity: 1, duration: .01}, 1.9)
            timeline.to(lineFive.querySelector('span'), {opacity: 1, duration: .01}, 2)

            timeline.to(lineSix.querySelector('span'), {opacity: 1, duration: .01}, 2.1)
//bottom to mid show
            timeline.to(lineEleven.querySelector('span'), {opacity: 1, duration: .01}, 1.6)
            timeline.to(lineTen.querySelector('span'), {opacity: 1, duration: .01}, 1.7)
            timeline.to(lineNine.querySelector('span'), {opacity: 1, duration: .01}, 1.8)
            timeline.to(lineEight.querySelector('span'), {opacity: 1, duration: .01}, 1.9)
            timeline.to(lineSeven.querySelector('span'), {opacity: 1, duration: .01}, 2)

//top to mid hide
            timeline.to(lineOne.querySelector('span'), {opacity: 0, duration: .01}, 2)
            timeline.to(lineTwo.querySelector('span'), {opacity: 0, duration: .01}, 2.1)
            timeline.to(lineThree.querySelector('span'), {opacity: 0, duration: .01}, 2.2)
            timeline.to(lineFour.querySelector('span'), {opacity: 0, duration: .01}, 2.3)
            timeline.to(lineFive.querySelector('span'), {opacity: 0, duration: .01}, 2.4)
//bottom to mid hide
            timeline.to(lineEleven.querySelector('span'), {opacity: 0, duration: .01}, 2)
            timeline.to(lineTen.querySelector('span'), {opacity: 0, duration: .01}, 2.1)
            timeline.to(lineNine.querySelector('span'), {opacity: 0, duration: .01}, 2.2)
            timeline.to(lineEight.querySelector('span'), {opacity: 0, duration: .01}, 2.3)
            timeline.to(lineSeven.querySelector('span'), {opacity: 0, duration: .01}, 2.4)

            timeline.to(loader, {backgroundColor: 'black', duration: .01}, 2.8)
            timeline.to(lineSix.querySelector('span'), {color: '#a33921', duration: .01}, 2.8)
            timeline.to(loaderContent, {
                scale: window.innerWidth < 1300 ? ((window.innerWidth / 65 - 300) * -1) : 75,
                transform: window.innerWidth < 1300 ? 'translateX(95%)' : 'translateX(55%)',
                duration: 1,
                ease: "power1.out"
            }, 3.2)
            timeline.to(loader, {
                visibility: 'hidden',
                opacity: 0,
                transform: 'translateX(100%)',
                duration: 0.01,
                ease: "power1.out",
                onComplete: this.KillIntro
            }, 4.2)//852828
        } else {
            timeForTop = 0
        }

        this.scrollbarObject = null
        this.mobileNavbarTimeline = gsap.timeline()
        this.navBtnMobile = document.querySelector('.header__list__element__btn')
        this.navBtnMobileCallback = e => {
            e.preventDefault()
            this.AnimateNavbar()
        }
        this.navBtnMobile.addEventListener('click', this.navBtnMobileCallback)
        this.mobileNavOpen = false
        if (!document.querySelector('.images')) {
            document.querySelector('#app').style.position = 'fixed'
            this.scrollbarObject = new Scrollbar(
                document.querySelector('main'),
                document.querySelector('.scrollbar__block--border'),
                document.querySelector('.scrollbar'),
                ScrollTrigger
            )
            const scrollbarO = this.scrollbarObject
            this.scrollbarObject.moveScroll()
            ScrollTrigger.scrollerProxy(document.querySelector('main'), {
                scrollTop(value) {
                    if (arguments.length) {
                        scrollbarO.positionPage = value / scrollbarO.reduceScroll; // setter
                    }
                    return scrollbarO.getAmountScrolled();    // getter
                },
                getBoundingClientRect() {
                    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
                }
            });
        } else {
            let prevScrollpos = window.pageYOffset;
            window.onscroll = function() {
                let currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    document.querySelector(".header").style.top = "0";
                } else {
                    if (!this.mobileNavOpen) {
                        document.querySelector(".header").style.top = "-80px";
                    }
                }
                prevScrollpos = currentScrollPos;
            }
        }

        this.AnimateInformationLines()

        document.querySelectorAll('.top__title__box__main').forEach(ele => {
            timeForTop += .1
            timeline.fromTo(ele, {y: 0, yPercent: 110},
                { y: 0, yPercent: 0, duration: 1, ease: "power1.out"}, timeForTop)
        })
        document.querySelectorAll('.top__title__box').forEach(ele => {
            timeline.to(ele, { overflow: "visible", duration: 0.01, ease: "none"}, timeForTop + 1)
        })
        if (lineOne) {
            timeline.fromTo(".header", {transform: "translateY(-100%)"},
                {
                    transform: "translateY(0)", duration: 1, ease: "power1.out",
                    onComplete: this.AnimateTextBoxes, onStart: this.AnimatePortfolio, onStartParams: [this]
                }, timeForTop + .3)
        } else {
            timeline.fromTo(".header", {},
                {
                    duration: 1, ease: "power1.out",
                    onComplete: this.AnimateTextBoxes, onStart: this.AnimatePortfolio, onStartParams: [this]
                }, timeForTop + .3)
        }
        if (document.querySelector('.about')) {
            if (window.innerWidth <= 900) {
                document.querySelector('.about__svg-box__figure').style.opacity = 0
                timeline.fromTo(".about__svg-box__figure",
                    {opacity: 0, transform: "translateY(5%)"}, {
                        opacity: '1',
                        transform: "translateY(0)",
                        duration: 1,
                        ease: "power1.inOut",
                        delay: .7
                    }, timeForTop + .6)
            }
        }


        const projectBtn = document.querySelector('.single__entry__info__about__btn')
        if (projectBtn) {
            gsap.fromTo(projectBtn, {left: '100%', transform: 'translate(-100%, 0'}, {
                scrollTrigger: {
                    trigger: projectBtn,
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                left: '0',
                transform: 'translate(0, 0)',
                ease: "power1.inOut",
                duration: 1
            })
            gsap.to(".single__entry__info__about__btn--left", {
                scrollTrigger: {
                    trigger: projectBtn,
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                height: '20%',
                ease: "power1.out",
                duration: 1
            })
            gsap.to(".single__entry__info__about__btn--right", {
                scrollTrigger: {
                    trigger: projectBtn,
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                height: '20%',
                ease: "power1.out",
                duration: 1
            })
            gsap.fromTo(".single__entry__info__about__btn--top", {width: '10%'}, {
                scrollTrigger: {
                    trigger: projectBtn,
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                width: '70%',
                ease: "power1.out",
                duration: 1
            })
            gsap.fromTo(".single__entry__info__about__btn--bottom", {width: '10%'}, {
                scrollTrigger: {
                    trigger: projectBtn,
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                width: '70%',
                ease: "power1.out",
                duration: 1
            })
        }
        if (document.querySelector('.about')) {
            document.querySelectorAll(".images__section__link").forEach(ele => {
                gsap.to(ele, {
                    scrollTrigger: {
                        trigger: ele,
                        scrub: true,
                        invalidateOnRefresh: true
                    },
                    x: '-30%',
                    ease: "none",
                    duration: 3
                })
            })

            const imagesBtn = document.querySelector('.images__bottom__btn')
            gsap.fromTo(imagesBtn, {left: '100%', transform: 'translate(-100%, -50%)'}, {
                scrollTrigger: {
                    trigger: imagesBtn,
                    scrub: true,
                    invalidateOnRefresh: true
                },
                left: '0',
                transform: 'translate(0, -50%)',
                ease: "power1.out",
                duration: 1
            })
            gsap.to(".images__bottom__btn--left", {
                scrollTrigger: {
                    trigger: imagesBtn,
                    scrub: true,
                    invalidateOnRefresh: true
                },
                height: '20%',
                ease: "power1.out",
                duration: 1
            })
            gsap.to(".images__bottom__btn--right", {
                scrollTrigger: {
                    trigger: imagesBtn,
                    scrub: true,
                    invalidateOnRefresh: true
                },
                height: '20%',
                ease: "power1.out",
                duration: 1
            })
            gsap.fromTo(".images__bottom__btn--top", {width: '10%'}, {
                scrollTrigger: {
                    trigger: imagesBtn,
                    scrub: true,
                    invalidateOnRefresh: true
                },
                width: '70%',
                ease: "power1.out",
                duration: 1
            })
            gsap.fromTo(".images__bottom__btn--bottom", {width: '10%'}, {
                scrollTrigger: {
                    trigger: imagesBtn,
                    scrub: true,
                    invalidateOnRefresh: true
                },
                width: '70%',
                ease: "power1.out",
                duration: 1
            })
            document.querySelectorAll('.contact__title__box__main').forEach(ele => {
                gsap.fromTo(ele, {y: 0, yPercent: 100}, {
                    scrollTrigger: {
                        trigger: ele,
                    },
                    y: 0, yPercent: 0,
                    ease: "power1.out",
                    duration: 1
                })
            })
        }

        document.querySelector('.footer').style.opacity = 1
        let delayFooter = -.1
        document.querySelectorAll('.footer__links__list__element__link').forEach( ele => {
            ele.style.transform = 'translateY(100%)'
            delayFooter += .1
            if (document.querySelector('.images')) {
                gsap.fromTo(ele, {y: 0, yPercent: 120}, {
                    scrollTrigger: {
                        trigger: ele
                    },
                    y: 0, yPercent: 0,
                    ease: "power1.out",
                    duration: .5,
                    delay: delayFooter
                })
            } else {
                gsap.fromTo(ele, {y: 0, yPercent: 120}, {
                    scrollTrigger: {
                        trigger: ele,
                        scroller: "main"
                    },
                    y: 0, yPercent: 0,
                    ease: "power1.out",
                    duration: .5,
                    delay: delayFooter
                })
            }
        })

        if (document.querySelector('.navigation').style.opacity === '1') {
            this.mobileNavOpen = true
            this.AnimateNavbar()
        }
    }

    KillIntro() {
        document.querySelector('.loader').remove()
    }

    AnimateNavbar() {
        if (this.mobileNavOpen === false) {
            if (!document.querySelector('.images')) {
                this.scrollbarObject.pauseScroll()
            }
            this.mobileNavOpen = true
            this.mobileNavbarTimeline.pause()
            this.mobileNavbarTimeline = gsap.timeline()
            document.querySelector('.navigation').style.visibility = "visible"
            document.querySelector('.navigation').style.opacity = "1"
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--3",
                {
                    transform: 'scaleX(0)',
                    duration: .5,
                    ease: "power1.out"
                })
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--2",
                {
                    transform: 'scaleX(0)',
                    duration: .5,
                    ease: "power1.out"
                }, .2)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--1",
                {
                    transform: 'scaleX(0)',
                    duration: .5,
                    ease: "power1.out"
                }, .4)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--4",
                {
                    transform: 'translate(-50%, -50%) rotate(45deg) scaleX(1)',
                    duration: .5,
                    ease: "power1.out"
                }, .8)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--5",
                {
                    transform: 'translate(-50%, -50%) rotate(-45deg) scaleX(1)',
                    duration: .5,
                    ease: "power1.out"
                }, .8)
            this.mobileNavbarTimeline.fromTo(".navigation__background",
                {transform: 'scaleY(0)'},
                {
                    transform: 'scaleY(1)',
                    duration: 1,
                    ease: "power1.out"
                }, .6)
            document.querySelectorAll('.navigation__list__element__link__main').forEach(element => {
                this.mobileNavbarTimeline.fromTo(element,
                    {transform: 'translateY(150%)'},
                    {
                        transform: 'translateY(0)',
                        duration: .5,
                        ease: "power1.out"
                    }, 1)
            })
            this.mobileNavbarTimeline.fromTo(".navigation__credit",
                {opacity: '0'},
                {
                    opacity: '1',
                    duration: 1,
                    ease: "power1.out"
                }, 1.5)
        } else {
            if (!document.querySelector('.images')) {
                this.scrollbarObject.unpauseScroll()
            }
            this.mobileNavOpen = false
            this.mobileNavbarTimeline.pause()
            this.mobileNavbarTimeline = gsap.timeline()
            this.mobileNavbarTimeline.fromTo(".navigation",
                {opacity: '1'},
                {
                    opacity: '0',
                    duration: .5,
                    ease: "power1.out",
                    onComplete: this.HideNav
                }, 0)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--4",
                {
                    transform: 'translate(-50%, -50%) rotate(45deg) scaleX(0)',
                    duration: .5,
                    ease: "power1.out"
                }, 0)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--5",
                {
                    transform: 'translate(-50%, -50%) rotate(-45deg) scaleX(0)',
                    duration: .5,
                    ease: "power1.out"
                }, 0)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--3",
                {
                    transform: 'scaleX(1)',
                    duration: .5,
                    ease: "power1.out"
                }, .4)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--2",
                {
                    transform: 'scaleX(1)',
                    duration: .5,
                    ease: "power1.out"
                }, .4)
            this.mobileNavbarTimeline.to(".header__list__element__btn__line--1",
                {
                    transform: 'scaleX(1)',
                    duration: .5,
                    ease: "power1.out"
                }, .4)
        }
    }

    HideNav() {
        document.querySelector('.navigation').style.visibility = "hidden"
    }

    AnimateTextBoxes() {
        if (document.querySelector('.about')) {
            gsap.fromTo(".about__svg-box__left", {height: "0%"}, {height: "100%", duration: 1, ease: "power1.inOut"})
            gsap.fromTo(".about__svg-box__bottom", {width: "0%"}, {width: "100%", duration: 1, ease: "power1.inOut", delay: 1})
            const firstTextBox = document.querySelector('.about__content__text__box--1')
            const secondTextBox = document.querySelector('.about__content__text__box--2')
            let delayAbout = -.1
            document.querySelectorAll('.about__content__text__hey__line--real').forEach(ele => {
                delayAbout += .1
                gsap.fromTo(ele, {y: 0, yPercent: 99, opacity: 0}, {
                    scrollTrigger: {
                        trigger: ".about__content__text__hey",
                    },
                    y: 0, yPercent: 0,
                    opacity: 1,
                    ease: "power1.out",
                    duration: .3,
                    delay: delayAbout
                })
            })
            delayAbout = -.1
            firstTextBox.querySelectorAll('.about__content__text__box__line--letters').forEach(ele => {
                delayAbout += .1
                gsap.fromTo(ele, {y: 0, yPercent: 99, opacity: 0}, {
                    scrollTrigger: {
                        trigger: firstTextBox,
                    },
                    y: 0, yPercent: 0,
                    opacity: 1,
                    ease: "power1.out",
                    duration: .3,
                    delay: delayAbout
                })
            })
            delayAbout = -.1
            secondTextBox.querySelectorAll('.about__content__text__box__line--letters').forEach(ele => {
                delayAbout += .1
                gsap.fromTo(ele, {y: 0, yPercent: 100, opacity: 0}, {
                    scrollTrigger: {
                        trigger: secondTextBox,
                    },
                    y: 0, yPercent: 0,
                    opacity: 1,
                    ease: "power1.out",
                    duration: .3,
                    delay: delayAbout
                })
            })
            setTimeout(() => {
                document.querySelectorAll('.projects__box__line--letters').forEach(ele => {
                    gsap.fromTo(ele, {y: 0, yPercent: 110}, {
                        scrollTrigger: {
                            trigger: ele,
                        },
                        y: 0, yPercent: 0,
                        ease: "power1.out",
                        duration: 1
                    })
                })
            }, 500)
        }
    }

    AnimateStartProject() {
        if (document.querySelector('.top__title__btn-box')) {
            gsap.to(".top__title__btn-box", {opacity: 1, duration: 1, ease: "power1.in"})
        }
        gsap.to(".footer", {opacity: 1, duration: 1, ease: "power1.in"})
    }

    AnimateInformationLines() {
        if (document.querySelector('.information')) {
            gsap.to(".information__row__column__img--left", {
                scrollTrigger: {
                    trigger: ".information__row__column__img",
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                height: '70%',
                ease: "power1.out",
                duration: 1
            })
            gsap.fromTo(".information__row__column__img--right", {height: '10%'},{
                scrollTrigger: {
                    trigger: ".information__row__column__img",
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                height: '70%',
                ease: "power1.out",
                duration: 1
            })
            gsap.to(".information__row__column__img--top", {
                scrollTrigger: {
                    trigger: ".information__row__column__img",
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                width: '20%',
                ease: "power1.out",
                duration: 1
            })
            gsap.fromTo(".information__row__column__img--bottom", {width: '100%'}, {
                scrollTrigger: {
                    trigger: ".information__row__column__img",
                    scrub: true,
                    invalidateOnRefresh: true,
                    scroller: "main"
                },
                width: '20%',
                ease: "power1.out",
                duration: 1
            })
        }
    }

    AnimateProjectTypes() {
        let delay = -.2
        document.querySelectorAll('.single__entry__info__types__type').forEach(element => {
            delay += .2
            gsap.fromTo(element, {opacity: 0, transform: 'translateX(-100%)'}, {
                scrollTrigger: {
                    trigger: ".single__entry__info__types",
                    scroller: "main"
                },
                transform: 'translateX(0)',
                opacity: 1,
                duration: 1,
                ease: "power1.out",
                delay: delay
            })
        })
        gsap.fromTo(".single__entry__info__about__text", {opacity: 0, transform: 'translateY(20%)'}, {
            scrollTrigger: {
                trigger: ".single__entry__info__about__text",
                scroller: "main"
            },
            transform: 'translateY(0)',
            opacity: 1,
            duration: 1,
            ease: "power1.out",
            delay: .2
        })
    }

    AnimatePortfolio(itself) {
        itself.AnimateStartProject()
        if (document.querySelector('.information')) {
            document.querySelectorAll('.information__row__column__text').forEach(element => {
                gsap.fromTo(element, {opacity: 0, transform: 'translateY(20%)'}, {
                    scrollTrigger: {
                        trigger: element,
                        scroller: "main"
                    },
                    transform: 'translateY(0)',
                    opacity: 1,
                    duration: 1,
                    ease: "power1.out",
                })
            })
            gsap.to(".information__row__column__img--top", {
                transform: 'scaleX(1)',
                duration: 1,
                ease: "power1.out",
            })
            gsap.to(".information__row__column__img--left", {
                transform: 'scaleY(1)',
                duration: 1,
                ease: "power1.out"
            })
            gsap.to(".information__row__column__img--right", {
                transform: 'scaleY(1)',
                duration: 1,
                ease: "power1.out",
            })
            gsap.to(".information__row__column__img--bottom", {
                transform: 'scaleX(1)',
                duration: 1,
                ease: "power1.out"
            })
            gsap.fromTo(".information__row__column__img--img",
                {opacity: 0 , transform: "translateY(5%)"}, {
                    opacity: '1',
                    transform: "translateY(0)",
                    duration: 1,
                    ease: "power1.inOut",
                    delay: .7
                })
        }
        if (document.querySelector('.single')) {
            const singleImage = document.querySelector('.single__entry__img__img')
            const topLine = document.querySelector('.single__entry__line')
            const bottomLine = document.querySelector('.single__entry__line--bottom')
            gsap.fromTo(singleImage, {opacity: '0'}, {
                opacity: '1',
                duration: 3,
                ease:
                    "rough({ template:none.out,strength:1,points:20,taper:none,randomize: true,clamp: false})"
            })
            document.querySelectorAll('.single__entry__img__title__line--main').forEach(element => {
                gsap.fromTo(element, {y: 0, yPercent: 100, x: 0, xPercent: -50}, {
                    y: 0, yPercent: 0,
                    x: 0, xPercent: -50,
                    duration: 1,
                    ease: "power1.out",
                    delay: 1
                })
            })
            gsap.fromTo(topLine, {transform: 'scaleX(0)'}, {
                transform: 'scaleX(1)',
                duration: 1,
                ease: "power1.out",
            })
            gsap.fromTo(bottomLine, {transform: 'scaleX(0)'}, {
                transform: 'scaleX(1)',
                duration: 1,
                ease: "power1.out",
                onComplete: itself.AnimateProjectTypes
            })
        }
        if (document.querySelector('.portfolio')) {
            document.querySelectorAll('.portfolio__box').forEach(box => {
                const boxImage = box.querySelector('.portfolio__box__thick')
                const number = box.querySelector('.portfolio__box__thin__number--line')
                const line = box.querySelector('.portfolio__box__thin__line')
                let translate = 50
                if (box.classList.contains('portfolio__box--right')) {
                    translate *= -1
                }
                gsap.fromTo(boxImage, {transform: 'translate('+translate+'%, 30%)'}, {
                    scrollTrigger: {
                        trigger: box,
                        scroller: "main"
                    },
                    transform: 'translate(0, 0)',
                    ease: "power1.out",
                    duration: 1
                })
                gsap.fromTo(boxImage, { opacity: 0}, {
                    scrollTrigger: {
                        trigger: box,
                        scroller: "main"
                    },
                    opacity: 1,
                    ease: "power1.in",
                    duration: 1,
                    delay: .2
                })
                gsap.fromTo(number, { transform: "translateY(100%)"}, {
                    scrollTrigger: {
                        trigger: box,
                        scroller: "main"
                    },
                    transform: "translateY(0)",
                    ease: "power1.out",
                    duration: .5,
                    delay: .7
                })
                gsap.fromTo(line, { height: "0px"}, {
                    scrollTrigger: {
                        trigger: box,
                        scroller: "main"
                    },
                    height: "100px",
                    ease: "power1.out",
                    duration: .5,
                    delay: .8
                })
            })
        }
    }

    KillListeners() {
        this.navBtnMobile.removeEventListener('click', this.navBtnMobileCallback)
        if (this.scrollbarObject) {
            this.scrollbarObject.killListeners()
            delete this.scrollbarObject
        }
    }
}