import * as THREE from 'three'
import Figure from './Figure'

const perspective = 800

export default class Scene {
    constructor() {
        this.container = document.getElementById('stage')
        // this.cameraY = 0
        // this.startingScroll = window.scrollY
        this.scene = new THREE.Scene()
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.container,
            alpha: true
        })
        this.renderer.setClearColor(new THREE.Color('#000000'), 1)

        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.renderer.setPixelRatio(window.devicePixelRatio)

        this.initLights()
        this.initCamera()

        this.figure = new Figure(this.scene, () => {
            this.update()
        })

        this.resizeCallBack = () =>
        {
            // Update sizes
            this.width = window.innerWidth
            this.height = window.innerHeight

            // Update camera
            this.camera.aspect = this.width / this.height
            this.camera.updateProjectionMatrix()

            // Update renderer
            this.renderer.setSize(this.width, this.height)
            this.camera.fov = (180 * (2 * Math.atan(window.innerHeight / 2 / perspective))) / Math.PI
            //this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        }
        window.addEventListener('resize', this.resizeCallBack)
    }

    initLights() {
        const ambientlight = new THREE.AmbientLight(0xffffff, 2)
        this.scene.add(ambientlight)
    }

    initCamera() {
        const fov =
            (180 * (2 * Math.atan(window.innerHeight / 2 / perspective))) /
            Math.PI

        this.camera = new THREE.PerspectiveCamera(
            fov,
            window.innerWidth / window.innerHeight,
            1,
            1000
        )
        this.camera.position.set(0, 0, perspective)
        // document.addEventListener('scroll', (e) => {
        //     this.cameraY = -this.startingScroll + window.scrollY
        //
        // })
    }

    moveScroll() {
        // this.cameraY *= .94
        //this.camera.position.y = -this.cameraY
    }

    update() {
        if (this.renderer === undefined) return
        if (this.scene) {
            requestAnimationFrame(this.update.bind(this))


            this.figure.update()
            //this.moveScroll()

            this.renderer.render(this.scene, this.camera)
        }
    }

    killListeners() {
        cancelAnimationFrame(this.id)
        this.figure.killListeners()
        delete this.figure
        window.removeEventListener('resize', this.resizeCallBack)
        this.renderer.domElement.addEventListener('dblclick', null, false); //remove listener to render
        this.scene = null;
        this.camera = null;

    }
}
