<template>
  <section class="contact">
    <div class="contact__title">
      <div class="contact__title--1 contact__title__box">
        <h1 class="contact__title__box__main">Chętnie</h1>
        <h1 class="contact__title__box__sub">Chętnie</h1>
      </div>
      <div class="contact__title--1 contact__title__box">
        <h1 class="contact__title__box__main">stworzę</h1>
        <h1 class="contact__title__box__sub">stworzę</h1>
      </div>
      <div class="contact__title--1 contact__title__box">
        <h1 class="contact__title__box__main">coś</h1>
        <h1 class="contact__title__box__sub">coś</h1>
      </div>
      <div class="contact__title--1 contact__title__box">
        <h1 class="contact__title__box__main">dla Ciebie<span>.</span></h1>
        <h1 class="contact__title__box__sub">dla <span>Ciebie.</span></h1>
      </div>
      <div class="contact__title--1 contact__title__box contact__title--margin">
        <h1 class="contact__title__box__main">Myślisz o</h1>
        <h1 class="contact__title__box__sub">Myślisz o</h1>
      </div>
      <div class="contact__title--1 contact__title__box">
        <h1 class="contact__title__box__main">Projekcie?</h1>
        <h1 class="contact__title__box__sub">Projekcie?</h1>
      </div>
      <div class="contact__title--1 contact__title__box contact__title__box--padding">
        <h1 class="contact__title__box__main contact__title__box__main--change contact__title__box--last">
          <router-link to="/kontakt">Porozmawiajmy!</router-link>
        </h1>
        <h1 class="contact__title__box__sub">Porozmawiajmy!</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactBlock'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
html body div#app main section.contact div.contact__title div.contact__title--1.contact__title__box.contact__title__box--padding h1.contact__title__box__main.contact__title__box__main--change.contact__title__box--last a {
  color: $color-orange;
  text-decoration: none;
}
.contact {
  background: transparent;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  @include respond(tab-land-plus) {
    width: 95%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    padding-top: 80px;

    @include respond(tab-land) {
      padding-top: 0;
    }

    &__box {
      overflow: hidden;
      position: relative;
      margin-top: -1.1vw;

      &--padding {
        padding-bottom: 30px;
      }

      & h1 {
        color: $color-white;
        line-height: 1.2;
        font-size: 7vw;
        font-family: 'Monument', 'sans-serif';
        font-weight: 700;

        & span {
          color: $color-orange;
        }
      }

      &__sub {
        opacity: 0;
        pointer-events: none;
      }

      &__main {
        position: absolute;

        &--change {
          //background: -webkit-linear-gradient(#eee, #333);
          //-webkit-background-clip: text;
          //-webkit-text-fill-color: transparent;
          color: $color-orange;
          cursor: pointer;
          transition: color 1s ease;
          &:hover {
          }
        }
      }

      &--last {
        display: inline-block;
        color: $color-orange !important;

        &:hover::after {
          animation-name: underLeftToRight;
          animation-duration: 0.8s;
        }
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          bottom: -.8vw;
          left: 0;
          height: .8vw;
          background: $color-orange;
          //transition: width 1s ease;
          transition: cubic-bezier(0.19, 1, 0.22, 1) 0.8s width;
          animation-name: underRightToLeft;
          animation-duration: 0.3s;
          animation-fill-mode: both;
          animation-direction: alternate;

        }
      }

    }
    &--margin {
      margin-top: 2rem;
    }
  }
}
</style>
