<template>
  <div class="loader">
    <div class="loader__content">
        <span class="loader__content__line--1 loader__content__line">
            <span class="loader__content__line__text--2">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--2 loader__content__line">
            <span class="loader__content__line__text--1">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--3 loader__content__line">
            <span class="loader__content__line__text--2">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--4 loader__content__line">
            <span class="loader__content__line__text--2">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--5 loader__content__line">
            <span class="loader__content__line__text--1">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--6 loader__content__line">
            <span class="loader__content__line__text--1">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--7 loader__content__line">
            <span class="loader__content__line__text--1">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--8 loader__content__line">
            <span class="loader__content__line__text--2">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--9 loader__content__line">
            <span class="loader__content__line__text--2">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--10 loader__content__line">
            <span class="loader__content__line__text--1">mój standard to perfekcja</span>
        </span>
      <span class="loader__content__line--11 loader__content__line">
            <span class="loader__content__line__text--2">mój standard to perfekcja</span>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader {
  background-color: #a33921;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;

  &__content {
    height: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__line {
      flex: 1;
      font-size: 4vw;
      pointer-events: none;
      position: relative;
      width: 100%;

      &__text {
        &--1 {
          font-weight: 700;
          opacity: 0;
          color: black;
        }

        &--2 {
          opacity: 0;
          color: black;
          -webkit-text-stroke: 2px black;
          -webkit-text-fill-color: #a33921;
          font-weight: 700;
          @include respond(phone) {
            -webkit-text-stroke: 1px black;
            -webkit-text-fill-color: #a33921;
          }
        }
      }

      & span {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        line-height: 1.2;
        font-family: Monument, "sans-serif";
      }
      &--5 {
        & span {
          top: 100%;
        }
      }
      &--6 {
        & span {
          opacity: 1;
        }
      }
      &--7 {
        & span {
          top: 0;
        }
      }
    }
  }
}
</style>
