<template>
  <TopBlock />
  <AboutBlock />
  <ProjectsBlock />
  <ImagesBlock />
  <ContactBlock />
</template>

<script>
// @ is an alias to /src
import TopBlock from '@/components/Main/TopBlock.vue'
import AboutBlock from '@/components/Main/AboutBlock.vue'
import ProjectsBlock from '@/components/Main/ProjectsBlock.vue'
import ImagesBlock from '@/components/Main/ImagesBlock.vue'
import ContactBlock from '@/components/Main/ContactBlock.vue'
import Timeline from '@/assets/Timeline'

export default {
  name: 'HomeView',
  components: {
    TopBlock,
    AboutBlock,
    ProjectsBlock,
    ImagesBlock,
    ContactBlock
  },
  data() {
    return {
      timeline: {}
    }
  },
  mounted() {
    this.timeline = new Timeline()
  },
  beforeUnmount() {
    this.timeline.KillListeners()
    delete this.timeline
  }
}
</script>
