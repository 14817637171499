<template>
  <div v-if="notMainPage" class="scrollbar">
    <div class="scrollbar__block--border">
      <div class="scrollbar__block"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollBar',
  computed: {
    notMainPage() {
      return this.$route.path !== '/';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.scrollbar {
  position: absolute;
  z-index: 6;
  background: $color-dark-gray;
  width: 6px;
  right: 0;
  top: 0;
  opacity: 0;
  transition: all 1s ease-in;
  height: 100vh;

  @include respond(tab-port) {
    width: 2px;
  }

  &:hover {
    opacity: 1;
    transition: all .5s ease;
  }

  &__block {
    background: transparent;
    width: 100%;
    height: 100%;
    &--border {
      width: 6px;
      position: absolute;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      top: 0;
      right: 0;
      height: 10%;
      background: $color-white;

      @include respond(tab-port) {
        width: 2px;
      }
    }
  }
}

.scrollbar--visible {
  opacity: 1;
  transition: all .5s ease-in;
}
</style>
