<template>
  <section class="footer">
    <div class="footer__links">
      <ul class="footer__links__list">
        <li class="footer__links__list__element">
          <a href="https://www.linkedin.com/in/arkadiusz-krupka" class="footer__links__list__element__link"><span>LinkedIn</span></a>
          <a href="https://www.linkedin.com/in/arkadiusz-krupka" class="footer__links__list__element__sub"><span>LinkedIn</span></a>
        </li>
        <li class="footer__links__list__element">
          <a href="https://github.com/FrostiaKk" class="footer__links__list__element__link"><span>GitHub</span></a>
          <a href="https://github.com/FrostiaKk" class="footer__links__list__element__sub"><span>GitHub</span></a>
        </li>
      </ul>
      <ul class="footer__links__list footer__links__list--right">
        <li class="footer__links__list__element">
          <a href="tel:880973563" class="footer__links__list__element__link"><span>+48 880 973 563</span></a>
          <a href="tel:880973563" class="footer__links__list__element__sub"><span>+48 880 973 563</span></a>
        </li>
        <li class="footer__links__list__element">
          <a href="mailto:kontakt@sdak.pl" class="footer__links__list__element__link"><span>kontakt@sdak.pl</span></a>
          <a href="mailto:kontakt@sdak.pl" class="footer__links__list__element__sub"><span>kontakt@sdak.pl</span></a>
        </li>
      </ul>
    </div>
  </section>
  <div class="credits">
    <div class="credits__text">
      © 2022 Software Development Arkadiusz Krupka
    </div>
  </div>

</template>

<script>
export default {
  name: 'FooterBlock'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  margin: 20rem 2rem 0;
  position: relative;
  background: $color-orange;
  opacity: 0;

  @include respond(tab-land-plus) {
    margin: 20vw 1% 0;
  }
  &__links {
    padding: 8rem 5%;
    display: flex;
    justify-content: space-between;
    @include respond(tab-port) {
      padding: 10vw 5%;
    }

    &__list {
      display: flex;
      justify-content: flex-start;
      width: 40%;
      @include respond(tab-port) {
        width: 50%;
        flex-direction: column;
      }

      &--right {
        width: 60%;
        justify-content: flex-end;
        @include respond(tab-port) {
          width: 50%;
          align-items: end;
        }
      }

      &__element {
        list-style: none;
        padding-right: 30px;
        font-size: 1.8rem;
        font-weight: 700;
        color: $color-white;
        position: relative;
        overflow: hidden;
        padding-bottom: 5px;

        @include respond(tab-port) {
          font-size: 2.3vw;
          padding-right: 0;
        }

        &__sub {
          opacity: 0;
          pointer-events: none;
        }

        &__link {
          position: absolute;
          left: 0;
          top: 0;
          color: $color-white;
          text-decoration: none;

          & span {
            position: relative;
            &:hover::after {
              animation-name: underLeftToRight;
              animation-duration: 0.8s;
            }
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -.2vw;
              width: 100%;
              height: .1vw;
              background: $color-white;
              transition: cubic-bezier(0.19, 1, 0.22, 1) 0.8s width;
              animation-name: underRightToLeft;
              animation-duration: 0.3s;
              animation-fill-mode: both;
              animation-direction: alternate;
            }
          }
        }
      }
    }
  }
}

.credits {
  width: 100%;
  position: relative;
  height: 2rem;
  &__text {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    background: black;
    color: $color-dark-gray;
    font-size: .7rem;
  }
}
</style>
