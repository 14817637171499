export default class Scrollbar {
    constructor(main, scrollbar, scrollbarLine, scrollTrigger) {
        this.scrollbarClicked = false;
        this.scrollTrigger = scrollTrigger
        this.main = main
        this.scrollbar = scrollbar
        this.scrollbarLine = scrollbarLine
        this.reduceScroll = 0.67
        this.mouseY = 0;
        this.positionPage = 0;
        this.previousPositionPage = 0
        this.first = true
        this.allowScroll = true
        this.height = .1
        this.setScrollbarBtnSize()
        this.scrollbarLineCallback = e => {
            if(this.scrollbarClicked === false) {
                this.clickOnScrollBar(e.clientY)
            }
        }
        this.singleBlock = document.querySelector('.single')
        this.scrollbarLine.addEventListener('click', this.scrollbarLineCallback)
        this.mouseMoveCallback = event => {
            event = event || window.event;
            this.mouseY = event.clientY
        }
        document.addEventListener('mousemove', this.mouseMoveCallback)
        this.wheelCallback = e => {
            this.cameraY += e.deltaY * 0.07
        }
        document.addEventListener('wheel', this.wheelCallback)
        this.scrollbarMouseDownCallBack = e => {
            e.preventDefault()
            this.scrollbarClicked = e.layerY
        }
        this.scrollbar.addEventListener('mousedown', this.scrollbarMouseDownCallBack)

        this.scrollbarMouseUpCallBack = () => {
            if(this.scrollbarClicked) {
                setTimeout(() => {this.scrollbarClicked = false}, 10);
            }
        }
        document.addEventListener('mouseup', this.scrollbarMouseUpCallBack)
        this.keyDownCallBack = key => {
            key = event.keyCode
            if (key.keyCode == '38') {
                this.cameraY += -3.5
            }
            else if (key.keyCode == '40') {
                this.cameraY += 3.5
            }
        }
        document.addEventListener('keydown', this.keyDownCallBack)
        this.touchPos = 0
        this.touchStartCallBack = e => {
            this.touchPos = e.changedTouches[0].clientY;
        }
        document.body.addEventListener('touchstart', this.touchStartCallBack)
        this.touchMoveCallBack = e => {
            let newTouchPos = e.changedTouches[0].clientY;
            if (newTouchPos > this.touchPos) {
                this.cameraY += (newTouchPos - this.touchPos) * -0.1
            }
            if (e.changedTouches[0].clientY < this.touchPos) {
                this.cameraY += (e.changedTouches[0].clientY - this.touchPos) * -0.1
            }
            this.touchPos = newTouchPos
        }
        document.body.addEventListener('touchmove', this.touchMoveCallBack)
    }

    setScrollbarBtnSize() {
        this.height = window.innerHeight / this.main.offsetHeight
        this.scrollbar.style.height = this.height * 100 + "%"
    }

    moveScroll() {
        if (this.allowScroll) {
            if (this.scrollbarClicked) {
                const position = (this.positionPage * this.reduceScroll * window.innerHeight) / (this.main.offsetHeight - window.innerHeight)
                const positionInRect = ((window.innerHeight * this.height) / (window.innerHeight)) * position
                const difference = this.scrollbarClicked - (positionInRect)
                this.clickOnScrollBar(this.mouseY - difference)
            }
            if (this.first) {
                this.cameraY = 0
                this.first = false
            }
            this.cameraY *= .96
            if (!((this.positionPage + this.cameraY) * this.reduceScroll <= this.main.offsetHeight - window.innerHeight)) {
                if (this.cameraY > 0) {
                    this.cameraY = 0
                }
                this.positionPage = (this.main.offsetHeight - window.innerHeight) / this.reduceScroll
            }
            this.checkDisplay()
            this.changeVisibility()
            this.changeNavbar()
            this.previousPositionPage = this.positionPage
            this.positionPage += this.cameraY
            if (this.positionPage < 0) {
                this.previousPositionPage = 0
                this.positionPage = 0
            }
            if (!document.querySelector('.images')) {
                this.transformPage(this.positionPage)
            }
            this.scrollTrigger.update()
        }
        requestAnimationFrame(() => {
            if (this.scrollTrigger) {
                this.moveScroll()
                this.setScrollbarBtnSize()
                if (this.singleBlock) {
                    this.scrollTrigger.refresh()
                    this.singleBlock = null
                }
            }
        })
    }

    checkDisplay() {
        if (this.main.style.display === 'none') {
            this.cameraY = 0
        }
    }

    changeNavbar() {
        if (this.positionPage > this.previousPositionPage) {
            document.querySelector(".header").style.top = "-100px";
        }
        if (this.positionPage < this.previousPositionPage || this.main.style.display === 'none') {
            document.querySelector(".header").style.top = "0";
        }
    }

    changeVisibility() {
        if (this.cameraY > 0.001 || this.cameraY < -0.001) {
            if (!this.scrollbarLine.classList.contains('scrollbar--visible')) {
                this.scrollbarLine.classList.add('scrollbar--visible')
            }
        } else {
            if (this.scrollbarLine.classList.contains('scrollbar--visible')) {
                this.scrollbarLine.classList.remove('scrollbar--visible')
            }
        }
    }

    transformPage(amount) {
        if (amount*this.reduceScroll > this.main.offsetHeight-window.innerHeight) {
            const difference = (amount*this.reduceScroll) - (this.main.offsetHeight-window.innerHeight)
            this.previousPositionPage = this.positionPage
            this.positionPage -= difference/this.reduceScroll
        }
        this.main.style.transform = `scale(1) translate3d(0px, -${amount*this.reduceScroll}px, 0px)`
        const scrollbarAmount = (window.innerHeight*(1 - this.height))/((this.main.offsetHeight-window.innerHeight)/(amount*this.reduceScroll))
        this.scrollbar.style.transform = `translate3d(0px, ${scrollbarAmount}px, 0px)`
    }

    clickOnScrollBar(y) {
        const amount = (y*(this.main.offsetHeight - window.innerHeight))/window.innerHeight
        this.previousPositionPage = this.positionPage
        this.positionPage = amount/this.reduceScroll
    }

    getAmountScrolled() {
        return this.positionPage*this.reduceScroll
    }

    pauseScroll() {
        this.allowScroll = false
        this.cameraY = 0
    }

    unpauseScroll() {
        this.allowScroll = true
        this.cameraY = 0
    }

    killListeners() {
        this.scrollTrigger = null
        document.removeEventListener('mousemove', this.mouseMoveCallback)
        this.scrollbarLine.removeEventListener('click', this.scrollbarLineCallback)
        document.removeEventListener('wheel', this.wheelCallback)
        this.scrollbar.removeEventListener('mousedown', this.scrollbarMouseDownCallBack)
        document.removeEventListener('mouseup', this.scrollbarMouseUpCallBack)
        document.removeEventListener('keydown', this.keyDownCallBack)
        document.body.removeEventListener('touchstart', this.touchStartCallBack)
        document.body.removeEventListener('touchmove', this.touchMoveCallBack)
    }
}